<template>
    <v-container fluid grid-list-lg>
        <v-row class="my-2">
            <v-col>
                <div class="text-body-1"></div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Todo',
};
</script>
